<script setup lang="ts">
import { useEventListing, useTerms } from '~/api/drupal'
import { ParagraphEventListing } from '~/api/graphql/generated'

const props = defineProps<{
  entity: ParagraphEventListing
}>()

useNoCache()

const route = useRoute()
const { t } = useI18n()

const showFilter = props.entity.fieldShowFilter || false
const eventType = props.entity.fieldEventTypeTaxonomy?.entity?.tid?.toString()
const eventCategory = props.entity.fieldEventCategory?.entity?.tid?.toString()
const filterBy = props.entity.fieldFilterBy ? props.entity.fieldFilterBy : 'type'

const { terms: eventTypes } = await useTerms(['event_type'])
const { terms: eventCategories } = await useTerms(['event_category'])

const filter = reactive({
  discriminator: filterBy,
  type: route.query.type ? (route.query.type as string).split(',') : [],
  location: route.query.location ? (route.query.location as string).split(',') : ['1', '0'],
})

const taxonomies = {
  eventTypes: eventType ? [eventType] : eventTypes.value.event_type.map(({ id }) => id),
  eventCategories: eventCategory ? [eventCategory] : eventCategories.value.event_category.map(({ id }) => id),
}

const { years, page, pages, noResults, allUsedTypeIds } = await useEventListing(filter, taxonomies)

const options = {
  ...(showFilter
    ? {
        type: (filterBy === 'type' ? eventTypes.value.event_type : eventCategories.value.event_category).filter(
          (term) => allUsedTypeIds.includes(term.id),
        ),
      }
    : {}),
  location: [
    {
      value: '1',
      label: t('event.list.filters.locationPhysical'),
    },
    {
      value: '0',
      label: t('event.list.filters.locationOnline'),
    },
  ],
}
</script>

<template>
  <Events
    v-model:filter="filter"
    v-model:page="page"
    :text="{
      title: props.entity.fieldTitle || '',
      location: $t('event.conferenceList.filters.location'),
      noResults: $t('event.conferenceList.noResults'),
    }"
    :options="options"
    :years="years"
    :pages="pages"
    :no-results="noResults"
    :show-filter="showFilter"
  />
</template>
<style scoped lang="scss"></style>
